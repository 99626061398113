import templateUrl from './file-media.tpl.html';

export default function fileMediaComponent($log, BulkApplyService, FileMediaService, SceneCollectionService, SceneCollectionItemService, MediaLabelService, $q, $timeout) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			scene: '=?'
		},
		controller: function() {
			this.selectedLabel = null;
			this.labelsInUse = [];
			this.selectedDate = null;
			this.sortOption = 'name';
			this.selectedMediaUsage = 'all';
			this.mediaInUse = {};
			this.S3url = FileMediaService.getS3MediaUrl();
		},
		controllerAs: 'ctrl',
		templateUrl: templateUrl,
		link: function($scope, $element, attrs, ctrl) {
			ctrl.$q = $q;
			ctrl.$log = $log;
			ctrl.isCollapsed = false;

			$scope.$watch(
				function() {
					return SceneCollectionService.activeSceneCollectionId;
				},
				function(newValue, oldValue) {
					if (newValue !== oldValue) {
						ctrl.$log.info('changeeeee', SceneCollectionService.activeSceneCollectionId);
						ctrl.getMediaStats();
					}
				}
			);

			// Collections
			ctrl.FileMedia = FileMediaService.getAll();

			ctrl.unsubscribe = FileMediaService.subscribe(state => {
				ctrl.FileMedia = state.FileMedias;
				fetchLabelsInUse();
			});

			// Setup subscription of MediaLabel relation changes
			ctrl._relationChangeUuid = MediaLabelService
				.subscribeToLabelRelationChanges('filemedias', relationInstance => {
					fetchLabelsInUse();
				});

			ctrl.getMediaStats = function() {
				if (!SceneCollectionService.activeSceneCollectionId) {
					ctrl.mediaInUse = {};
					return;
				}

				SceneCollectionItemService.getSceneCollectionItemsForSceneCollection(SceneCollectionService.activeSceneCollectionId)
					.then(resp => {
						// Filter the objects where mediaType is "file"
						const filteredData = resp.filter(item => item.mediaType === 'file');

						// Get unique mediaIds
						const uniqueMediaIds = [...new Set(filteredData.map(item => item.mediaId))];

						// Prepare the result object
						const mediaIdCount = {};
						uniqueMediaIds.forEach(mediaId => {
							// Count how many times each mediaId appears in the filtered data
							const count = filteredData.filter(item => item.mediaId === mediaId).length;
							mediaIdCount[mediaId] = count;
						});

						ctrl.mediaInUse = mediaIdCount;
					});
			};

			const sceneCollectionUnsubscribe = SceneCollectionService.subscribe(() => ctrl.getMediaStats());

			// Functions
			ctrl.delete = id => {
				var confirmDelete = confirm('Are you sure you want to delete this media item?');
				if (confirmDelete) {
					FileMediaService.delete(id);
				}
			};
			ctrl.getDuration = id => FileMediaService.getDuration(id);
			ctrl.getEvent = id => FileMediaService.getEvent(id);
			ctrl.getOrientations = id => FileMediaService.getOrientations(id);
			ctrl.getThumbnailUrl = id => FileMediaService.getThumbnailUrl(id);
			ctrl.getType = id => FileMediaService.getType(id);
			ctrl.isSceneActive = function() {
				return !!SceneCollectionService.activeSceneCollectionId;
			};

			ctrl.getIcon = id => {
				const type = FileMediaService.getType(id);
				if (type === 'image') {
					return 'fa-picture-o';
				}
				else if (type === 'video') {
					return 'fa-film';
				}
				return 'fa-question';
			};

			ctrl.toggleBulk = function toggleBulkFileMedia(filemediaId) {
				BulkApplyService.toggleElement('filemedias', filemediaId);
			};

			ctrl.addToScene = function addFileToScene(id) {
				SceneCollectionService.addSceneCollectionItemToSceneCollection('file', id);
			};

			$scope.$on('$destroy', () => {
				ctrl.unsubscribe();
				sceneCollectionUnsubscribe();
				MediaLabelService.unsubscribeToLabelRelationChanges('filemedias', ctrl._relationChangeUuid);
				BulkApplyService.destroyNamespace('filemedias');
			});

			// Code below is for filtering/sorting
			function fetchLabelsInUse() {
				const allLabels = MediaLabelService.getAll();
				ctrl.labelsInUse = allLabels.filter(item => item.filemedias && item.filemedias.length > 0);
				ctrl.labelsInUse.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
			}

			ctrl.filterByLabel = function(item) {
				if (!ctrl.selectedLabel) return true;
				const labelIds = item.labels.map(label => label.id);
				return labelIds.includes(ctrl.selectedLabel.id);
			};

			ctrl.filterByMediaUsage = function(item) {
				const mediaInUseCount = ctrl.mediaInUse[item.id] || 0;

				switch (ctrl.selectedMediaUsage) {
					case 'inUse':
						return mediaInUseCount >= 1;
					case 'notInUse':
						return mediaInUseCount === 0;
					default:
						return true; // for 'all' or any other case
				}
			};

			ctrl.getUniqueDates = function() {
				let uniqueDates = new Set();
				ctrl.FileMedia.forEach(item => {
					const date = item.createdAt.slice(0, 10);
					uniqueDates.add(date);
				});
				return Array.from(uniqueDates).sort();
			};

			ctrl.filterByDate = function(date) {
				if (date) {
					return item => {
						return item.createdAt.slice(0, 10) === date;
					};
				}
				return item => true;
			};

			ctrl.clearFilters = function() {
				ctrl.selectedDate = null;
				ctrl.selectedLabel = null;
				ctrl.sortOption = 'name';
				ctrl.selectedMediaUsage = 'all';
			};

			ctrl.getDurationSort = function(item) {
				return FileMediaService.getDuration(item.id);
			};

			ctrl.getMediaInUseSort = function(item) {
				// Return the negative count for descending order. Items with higher counts come first.
				// If the item is not in mediaInUse, default to 0 to handle undefined counts.
				return -(ctrl.mediaInUse[item.id] || 0);
			};

			ctrl.getSortKey = function(sortOption) {
				if (sortOption === 'duration') {
					return ctrl.getDurationSort;
				}
				else if (sortOption === 'mediaInUse') {
					return ctrl.getMediaInUseSort;
				}
				return sortOption;
			};

			// video modal logic
			document.addEventListener('click', function(event) {
				if (event.target.classList.contains('modal')) {
					ctrl.closeModal();
				}
			});

			function closeModal(element) {
				const modalEvent = new CustomEvent('hide.bs.modal', { bubbles: true });
				element.dispatchEvent(modalEvent);
				if (!modalEvent.defaultPrevented) {
					element.classList.remove('in');
					element.style.display = 'none';
					element.setAttribute('aria-hidden', 'true');
				}
				const videos = document.querySelectorAll('#videoModal video');
				videos.forEach((video) => {
					video.pause(); // Pause the video
					video.currentTime = 0;
				});
			}

			ctrl.closeModal = function() {
				const modalElement = document.getElementById('videoModal');
				closeModal(modalElement);
			};

			function showModal(element) {
				const modalEvent = new CustomEvent('show.bs.modal', { bubbles: true });
				element.dispatchEvent(modalEvent);
				if (!modalEvent.defaultPrevented) {
					element.classList.add('in');
					element.style.display = 'block';
					element.setAttribute('aria-hidden', 'false');
				}
				const videos = document.querySelectorAll('#videoModal video');
				videos.forEach((video) => {
					video.play(); // Pause the video
					video.currentTime = 0;
				});
			}

			ctrl.openModal = function(item) {
				ctrl.currentItem = item;
				const modalElement = document.getElementById('videoModal');
				showModal(modalElement);
			};
		}
	};
}
