import templateUrl from './service-announcements.component.html';

export default function serviceAnnouncementsComponent(BulkApplyService, ServiceAnnouncementsService, SceneCollectionService) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			scene: '=?'
		},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl,
		link: function($scope, $element, attrs, ctrl) {
			ctrl.isCollapsed = false;
			ctrl.ServiceAnnouncements = ServiceAnnouncementsService.getAll();
			ctrl.unsubscribe = ServiceAnnouncementsService.subscribe(state => {
				ctrl.ServiceAnnouncements = state.ServiceAnnouncements;
			});

			$scope.$on('$destroy', () => {
				ctrl.unsubscribe();
				BulkApplyService.destroyNamespace('serviceannouncements');
			});

			ctrl.addToScene = function addToScene(id) {
				SceneCollectionService.addSceneCollectionItemToSceneCollection('serviceAnnouncement', id);
			};

			ctrl.toggleBulk = function toggleServiceAnnouncementForBulk(id) {
				BulkApplyService.toggleElement('serviceannouncements', id);
			};

			ctrl.isSceneActive = function() {
				return !!SceneCollectionService.activeSceneCollectionId;
			};
		}
	};
}
