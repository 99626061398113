import templateUrl from './scene-content.tpl.html';

export default function sceneContentComponent(
	$log, $q, PlayQueueService, FileMediaService, ServiceAnnouncementsService,
	SocialMediaService, SceneCollectionService, SceneCollectionItemService, BulkApplyService, moment
) {
	'ngInject';
	return {
		restrict: 'E',
		scope: {},
		bindToController: {
			scene: '='
		},
		controller: () => {},
		controllerAs: 'ctrl',
		templateUrl: templateUrl,
		link: function($scope, $element, attrs, ctrl) {
			ctrl.isCollapsed = false;
			ctrl.playingSceneCollection = null;
			ctrl.sceneCollections = [];

			ctrl.getSceneCollections = () => SceneCollectionService
				.getSceneCollectionsForScene(ctrl.scene.id)
				.then(sceneCollectionsResponse => {
					const sceneCollectionsPromises = sceneCollectionsResponse.map(sceneCollection => {
						sceneCollection.intervals = sceneCollection.intervals.map(interval => {
							interval.readable_begin = moment(interval.start_datetime).format('DD/MM HH:mm');
							interval.readable_end = moment(interval.end_datetime).format('DD/MM HH:mm');
							return interval;
						});

						sceneCollection.totalDuration = sceneCollection.sceneCollectionItems.reduce((accumulator, currentItem) => accumulator + currentItem.duration, 0);
						return sceneCollection;
					});

					return $q.all(sceneCollectionsPromises);
				})
				.then(sceneCollections => {
					ctrl.sceneCollections = sceneCollections;
					return SceneCollectionService.getActiveSceneCollectionId(ctrl.scene.id);
				})
				.then(sceneCollectionId => {
					ctrl.playingSceneCollection = sceneCollectionId;
				})
				.catch(err => {
					$log.error(`[SceneContent] getSceneCollections: ${err.message}`, err);
				});

			const sceneCollectionUnsubscribe = SceneCollectionService.subscribe(() => ctrl.getSceneCollections());

			ctrl.activeSceneCollection = null;
			ctrl.activeSceneCollectionItems = [];
			ctrl.setActiveSceneCollection = function setActiveSceneCollection(sceneCollectionId) {
				SceneCollectionItemService
					.getSceneCollectionItemsForSceneCollection(sceneCollectionId)
					.then(sceneCollectionItems => {
						ctrl.activeSceneCollectionItems = sceneCollectionItems;
						return SceneCollectionService.get(sceneCollectionId);
					}).then(sceneCollection => {
						ctrl.activeSceneCollection = sceneCollection;
						ctrl.activeSceneCollection.totalDuration = ctrl.activeSceneCollectionItems.reduce((accumulator, currentItem) => accumulator + currentItem.duration, 0);
						SceneCollectionService.setActiveSceneCollection(sceneCollectionId);
					}).catch(err => {
						$log.error(`[SceneContent] setActiveSceneCollection: ${err.message}`, err);
					});
			};

			const sceneCollectionItemUnsubscribe = SceneCollectionItemService.subscribe(state => {
				if (ctrl.activeSceneCollection) {
					ctrl.setActiveSceneCollection(ctrl.activeSceneCollection.id);
				}
			});

			ctrl.isIntervalActive = function(intervalEnd) {
				return new Date(intervalEnd) > new Date();
			};

			ctrl.removeActiveSceneCollection = function removeActiveSceneCollection() {
				ctrl.activeSceneCollection = null;
				ctrl.activeSceneCollectionItems = [];
				SceneCollectionService.removeActiveSceneCollection();
			};

			ctrl.orderUp = function orderSceneCollectionItemUp(sceneCollectionId, sceneCollectionItemId) {
				return SceneCollectionItemService
					.orderSceneCollectionItemUp(sceneCollectionId, sceneCollectionItemId)
					.catch(err => {
						$log.error(`[SceneContent] orderUp: ${err.message}`, err);
					});
			};

			ctrl.orderDown = function orderSceneCollectionItemDown(sceneCollectionId, sceneCollectionItemId) {
				return SceneCollectionItemService
					.orderSceneCollectionItemDown(sceneCollectionId, sceneCollectionItemId).catch(err => {
						$log.error(`[SceneContent] orderDown: ${err.message}`, err);
					});
			};

			ctrl.deleteSceneCollection = function deleteSceneCollection(sceneCollectionId) {
				if (confirm('Are you sure you want to delete the playlist?')) {
					SceneCollectionService
						.delete(sceneCollectionId)
						.then(() => {
							ctrl.getSceneCollections();
						})
						.catch(err => {
							$log.error(`[SceneContent] deleteSceneCollection: ${err.message}`, err);
						});
				}
			};

			ctrl.editSceneCollection = function editSceneCollection(sceneCollection) {
				SceneCollectionService.editSchedule(sceneCollection);
			};

			ctrl.deleteSceneCollectionItem = function(id) {
				return SceneCollectionItemService.delete(id);
			};

			// Add a property to store the copiedSceneCollectionId
			ctrl.copiedSceneCollectionId = SceneCollectionItemService.getCopiedSceneCollectionId();

			// Function to update copiedSceneCollectionId from the service
			ctrl.updateCopiedSceneCollectionId = function() {
				ctrl.copiedSceneCollectionId = SceneCollectionItemService.getCopiedSceneCollectionId();
			};

			// Watch for changes in the service and update the controller property
			$scope.$watch(function() {
				return SceneCollectionItemService.getCopiedSceneCollectionId();
			}, function(newVal, oldVal) {
				if (newVal !== oldVal) {
					ctrl.updateCopiedSceneCollectionId();
				}
			});

			// Function to store the ID of the SceneCollection to be copied
			ctrl.copySceneCollection = function(sceneCollectionId) {
				SceneCollectionItemService.setCopiedSceneCollectionId(sceneCollectionId);
				$log.info('Copied SceneCollection ID:', sceneCollectionId);
			};

			// Function to paste the copied SceneCollectionItems into another SceneCollection
			ctrl.pasteSceneCollectionItems = function(targetSceneCollectionId) {
				let sourceSceneCollectionId = SceneCollectionItemService.getCopiedSceneCollectionId();
				$log.info('Pasting SceneCollectionItems from:', sourceSceneCollectionId, 'to:', targetSceneCollectionId);
				if (!sourceSceneCollectionId || !targetSceneCollectionId) {
					$log.error('Both source and target SceneCollection IDs are required.');
					return;
				}

				SceneCollectionItemService
					.copyItemsToSceneCollection(sourceSceneCollectionId, targetSceneCollectionId)
					.then(() => {
						$log.info('SceneCollectionItems pasted successfully.');
						// Optional: Refresh or update UI components if necessary
					})
					.catch(err => {
						$log.error(`[SceneContent] pasteSceneCollectionItems: ${err.message}`, err);
					});
			};

			ctrl.durationIsEditable = function(sceneCollectionItem) {
				if (sceneCollectionItem.mediaType === 'file' && FileMediaService.getType(sceneCollectionItem.mediaId) === 'video') {
					// Disallow editing duration of FileMedia videos
					return false;
				}

				return true;
			};

			ctrl.validateAndSetDuration = function(id, duration) {
				let n = Math.floor(Number(duration));
				if (n > 0) {
					// Length is limited to 60 seconds (to ensure SM doesn't generate too long a queue in fillQueue)
					n = Math.min(n, 60);
					const newDuration = n * 1000;
					return SceneCollectionItemService
						.update({
							id,
							duration: newDuration
						})
						.then(function() {
							return false;
						})
						.catch(function(error) {
							$log.error(`Could not set duration for ${id}: `, error);
							return 'Error ' + error;
						});
				}

				return 'Not a positive number.';
			};

			ctrl.playNow = function playNow(sceneCollectionId) {
				if (confirm('Are you sure you want to play this now?')) SceneCollectionService.playNow(sceneCollectionId);
			};

			// Be sure to unsubscribe and destroy any selections that have been made
			$scope.$on('$destroy', () => {
				sceneCollectionUnsubscribe();
				sceneCollectionItemUnsubscribe();
				SceneCollectionService.removeActiveSceneCollection();
			});

			// Code below is for drag and drop.
			ctrl.placeholderIndex = null;

			ctrl.reorderItems = function(sourceItemId, targetItemId) {
				const sourceIndex = ctrl.activeSceneCollectionItems.findIndex(item => item.id === sourceItemId);
				const targetIndex = ctrl.activeSceneCollectionItems.findIndex(item => item.id === targetItemId);

				if (sourceIndex < 0 || targetIndex < 0) {
					return;
				}

				SceneCollectionItemService
					.reorderSceneCollectionItems(ctrl.activeSceneCollection.id, sourceItemId, targetItemId)
					.catch(err => {
						$log.error(`[SceneContent] reorderItems: ${err.message}`, err);
					});
			};

			function findElementWithAttribute(element, attributeName) {
				while (element) {
					if (element.hasAttribute(attributeName)) {
						return element;
					}
					element = element.parentElement;
				}
				return null;
			}

			$element[0].addEventListener('dragstart', function(event) {
				const targetElement = findElementWithAttribute(event.target, 'data-position');
				if (targetElement) {
					const itemId = targetElement.getAttribute('data-index');
					event.dataTransfer.setData('text/plain', itemId);
					event.dataTransfer.effectAllowed = 'move';
				}
			});

			$element[0].addEventListener('dragover', function(event) {
				event.dataTransfer.dropEffect = 'move';
				event.preventDefault();

				// Define the scroll sensitivity and speed
				const sensitivity = 50;
				const scrollSpeed = 10;

				// Determine the distance from the edge of the viewport
				const bottomEdge = window.innerHeight - event.clientY;

				// Add top menu height if exists
				const topMenu = document.getElementById('manager-menu');
				const topMenuHeight = topMenu ? topMenu.offsetHeight : 0;
				const topEdge = event.clientY - topMenuHeight;

				// If we're near the bottom of the viewport and not at the bottom of the page, scroll down
				if (bottomEdge < sensitivity && (window.innerHeight + window.scrollY) < document.body.offsetHeight) {
					window.scrollBy(0, scrollSpeed);
				}

				// If we're near the top of the viewport and not at the top of the page, scroll up
				else if (topEdge < sensitivity && window.scrollY > 0) {
					window.scrollBy(0, -scrollSpeed);
				}

				const targetElement = findElementWithAttribute(event.target, 'data-position');
				if (targetElement) {
					ctrl.placeholderIndex = parseInt(targetElement.getAttribute('data-position'));
				}
				$scope.$apply();
			});

			$element[0].addEventListener('drop', function(event) {
				event.preventDefault();
				const sourceItemId = parseInt(event.dataTransfer.getData('text/plain'));
				const targetElement = findElementWithAttribute(event.target, 'data-index');
				let targetItemId;
				if (targetElement) {
					targetItemId = parseInt(targetElement.getAttribute('data-index'));
				}

				if (sourceItemId !== targetItemId && targetItemId !== undefined) {
					ctrl.reorderItems(sourceItemId, targetItemId);
					$scope.$apply();
				}

				ctrl.placeholderIndex = null;
				$scope.$apply();
			});

			$element[0].addEventListener('dragend', function(event) {
				event.dataTransfer.clearData();
				ctrl.placeholderIndex = null;
				$scope.$apply();
			});
		}
	};
}
