import angular from 'angular';

const randomColors = [
	'#001f3f', // Navy
	'#0074D9', // Blue
	'#7FDBFF', // Aqua
	'#39CCCC', // Teal
	'#3D9970', // Olive
	'#2ECC40', // Green
	'#01FF70', // Lime
	'#C6C013', // Acid Green (yellowish)
	'#FF851B', // Orange
	'#FF4136', // Red
	'#85144b', // Maroon
	'#F012BE', // Fuchsia
	'#B10DC9' // Purple
];

class ColorService {
	constructor() {
		'ngInject';

		this.randomColors = randomColors;
	}

	getColors() {
		return randomColors;
	}

	getRandomColor() {
		return randomColors[Math.floor(Math.random() * randomColors.length)];
	}
}

export default angular.module('color.service', [])
	.service('ColorService', ColorService).name;
