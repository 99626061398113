import templateUrl from './scene-player-log.component.html';
import moment from 'moment';

class ScenePlayerLogController {
	constructor($log, $scope, PlayQueueService, SceneService, ApiService) {
		'ngInject';

		this._$log = $log;
		this._$scope = $scope;
		this._$PlayQueueService = PlayQueueService;
		this._$SceneService = SceneService;
		this._$ApiService = ApiService;
		this._$scope.playListLog = [];

		this.isCollapsed = false;

		this.handlePlayQueueChange = this.handlePlayQueueChange.bind(this);
		this.getPlayQueueForPlayback = this.getPlayQueueForPlayback.bind(this);
		this.addToplayListLog = this.addToplayListLog.bind(this);
		this.updateItemAsRemovedById = this.updateItemAsRemovedById.bind(this);
		this.addPlaylistItemFromCurrentlyPlaying = this.addPlaylistItemFromCurrentlyPlaying.bind(this);
	}

	$onInit() {
		this.getPlayQueueForPlayback(this.id);
		this._$PlayQueueService.subscribe(this.id, this.handlePlayQueueChange)
			.catch(err => {
				this._$log.error(`[SceneLogStatus] Could not subscribe to playQueue changes for scene id ${this.id}`, err);
			});

		this.unsubscribe = this._$SceneService.subscribeToId(this.id, scene => {
			if (!this.scene && scene && scene.currentlyPlayingId) {
				this.addPlaylistItemFromCurrentlyPlaying(scene.currentlyPlayingId);
			}
			this.scene = scene;
		});

		this._$scope.$on('$destroy', this.unsubscribe);
	}

	addPlaylistItemFromCurrentlyPlaying(currentlyPlayingId) {
		this._$ApiService.call('playlistitem/getPlaylistItem', { id: currentlyPlayingId })
			.then(playlistItem => {
				this.addToplayListLog(playlistItem);
			})
			.catch(err => {
				this._$log.error('[ScenePlayerLog] Could not load playlistItemfrom currentlyPlayingId', err);
			});
	}

	handlePlayQueueChange(err, playQueue) {
		if (err) {
			this._$log.error(`[SceneLogStatus] handlePlayQueueChange got error for scene id ${this.id}:`, err);
			return;
		}
		if (playQueue.removed && playQueue.removed === true) {
			this.updateItemAsRemovedById(playQueue);
		}
		else {
			this.addToplayListLog(playQueue);
		}
	}

	getPlayQueueForPlayback(sceneId) {
		this._$PlayQueueService.get(sceneId)
			.then(playlistItems => {
				for (const playlistItem of playlistItems) {
					this._$scope.playListLog.push(playlistItem);
				}
			})
			.catch(err => {
				this._$log.error(`[Player] Could not get current playQueue for scene id ${sceneId}`, err);
			});
	}

	addToplayListLog(playlistItem) {
		const exists = this._$scope.playListLog.find(item => item.id === playlistItem.id);
		if (!exists) {
			this._$scope.playListLog.push(playlistItem);
			if (this._$scope.playListLog.length > 7) {
				this._$scope.playListLog.shift();
			}

			this._$scope.playListLog.sort((a, b) => a.id - b.id);
		}
	}

	updateItemAsRemovedById(updateObject) {
		let item = this._$scope.playListLog.find(item => item.id === updateObject.id && item.sceneId === updateObject.sceneId);
		if (item) {
			item.removed = updateObject.removed;
			item.timePlayed = moment(updateObject.timePlayed).local();
			item.playbackEnded = moment(updateObject.playbackEnded).local();
		}
	}
}

export default {
	bindings: {
		id: '<'
	},
	controller: ScenePlayerLogController,
	templateUrl
};
