import angular from 'angular';

import BulkApplyService from 'common/bulk-apply.service';
import MediaLabelService from 'common/medialabel.service';
import ColorService from 'common/color.service';

import libraryMediaLabels from './library-media-labels.component';
import mediaLabels from './media-labels.component';

export default angular.module('medialabel', [
	BulkApplyService,
	MediaLabelService,
	ColorService
])
	.component('libraryMediaLabels', libraryMediaLabels)
	.component('mediaLabels', mediaLabels)
	.name;
